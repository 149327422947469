import { Container, Graphics, Ticker, Text } from "pixi.js";
import { IScene, Manager } from "../Manager";
// import { Player } from "../components/Player";
// import { LoaderScene } from "./LoaderScene";

export class TextScene extends Container implements IScene {
    public popup = false
    // for making our loader graphics...
    constructor({text,emoji}: {text?: string,emoji?: string}) {
        super();        
        let invisibleButton = new Graphics();
        // right order! rect -> fill
        invisibleButton.rect(0, 0, Manager.width, Manager.height);
        invisibleButton.fill({color:"black", alpha:0});
        invisibleButton.eventMode = "dynamic"
        invisibleButton.once("pointerdown", ()=>{
            // console.log("pointerdown!")
            Manager.nextScene({text:'evaluation!!'})
        })
        if (text){
            const textC = new Text({text})
            textC.anchor.x = 0.5
            textC.anchor.y = 0.5
            textC.position.x = Manager.width/2
            textC.position.y = 2*Manager.height/3
            this.addChild(textC); 
        }
        if (emoji){
            const textEmoji = new Text({text:emoji})
            textEmoji.scale.set(3)
            textEmoji.anchor.x = 0.5
            textEmoji.anchor.y = 0.5
            textEmoji.position.x = Manager.width/2
            textEmoji.position.y = Manager.height/3
            // text.position.x = Manager.width
            this.addChild(textEmoji);
        }
        
        this.addChild(invisibleButton);
    }
    
    public update(_ticker: Ticker): void {
        // To be a scene we must have the update method even if we don't use it.
    }
}