import * as d3 from "d3-color"
import * as d3Scale from "d3-scale"
import { Color } from "pixi.js";
export function hexToRGB(hex) {
  if (typeof hex == "number") {
    hex = hex.toString(16)
  }
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as any;
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  r /= 255, g /= 255, b /= 255;
  return { r, g, b }
}
export function hslToRGB({ h, s, l }) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return { r, g, b }
}
export function rgbToHex({r,g,b}){
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
export function hslToHex({ h, s, l }) {
  h /= 360;
  s /= 100;
  l /= 100;
  let { r, g, b } = hslToRGB({ h, s, l });
  return rgbToHex({r,g,b})
}
export function hexToHSL(hex) {
  if (typeof hex == "number") {
    hex = hex.toString(16)
  }
  let { r, g, b } = hexToRGB(hex)
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
  let HSL = new Object();
  HSL['h'] = h;
  HSL['s'] = s;
  HSL['l'] = l;
  return HSL;
}
export function hexToString(hex){
  return hex.toString(16)
}
export function hexToNumber(hexString){
  return parseInt(hexString, 16)
}
export function completeDict(dictColors){
  let newDict = {...dictColors}
  let keys = Object.keys(dictColors).map(d=>parseInt(d))
  keys.forEach((k,i,listA)=>{
    let initK = k
    let finalK = listA[i+1]
    let scale = d3Scale.scaleLinear([initK, finalK], [dictColors[initK], dictColors[finalK]])
    for (let i=initK; i<=finalK; i++){
      newDict[i] = scale(i)
    }
  })
  return newDict
}
export function changeTint(tint: Color, settings:{darker?:number, brighter?:number}={darker:0, brighter:0}){
  let new_color = d3.color(tint.toHex());
  // if (typeof tint == Color){
  //   let rgb = tint.toArray().slice(0,3).map((v,i)=>[['r','g','b'][i], v])
  //   new_color = d3.color(d3.rgb(rgb))
  // }else{
  //   new_color = d3.color(d3.rgb(hexToRGB(tint)))
  // }
  if (settings.brighter!=null){
    new_color = new_color.brighter(settings.brighter)
  }
  if (settings.darker!=null){
    new_color = new_color.darker(settings.darker)
  }
  // console.log(new_color)
  return new Color(new_color)
 
}
// hexToHSL(this.mainGraphics.tint.toString(16))