import { Manager } from './Manager';
import { LoaderScene } from './scenes/LoaderScene';
// import { SplashScene } from './scenes/SplashScene';
import { GameScene } from './scenes/GameScene';
import { TextScene } from './scenes/TextScene';
import { Settings } from './Settings';
import { EvaluationScene } from './scenes/EvaluationScene';

import { extensions, ExtensionType } from 'pixi.js';
import { getMidi } from './utils/io';
// import { ProvarScene } from './scenes/ProvarEvents';

// create a custom asset loader
const customAssetLoader = {
   extension: {
       type: ExtensionType.LoadParser,
       name: 'mid-asset-loader',
   },
   test(url) {
      // check if this new loader should be used...
	  try{
		return url.slice(url.length-4)==='.mid'
	  }catch(ex){
		return false
	  }
   },
   async load(url) {
       // load the asset...
	   	return await fetch(url).then(r=>{
			return r.arrayBuffer()
		}).then((arraybuff)=>{
			return getMidi(arraybuff)
		})
   },
};

// add the custom asset loader to pixi
extensions.add(customAssetLoader);

// import { Assets } from 'pixi.js';
// import { manifest } from './assets';
// import { Assets } from 'pixi.js';
// const 
// const app = new Application<Renderer<HTMLCanvasElement>>();

const loaderPipeline = [
	{id:"loader", sceneFactory: ()=>new LoaderScene("sprites/logo.png",0)},
]
const gamePipeline = [		
	...loaderPipeline,
	// {id: 'ev', sceneFactory: (args:any)=>{
	// 	let jsonLevelSettings = Assets.get("level_0")
	// 	let jsonLevel = Object.values(jsonLevelSettings.tracks)[0] as any
	// 	return new EvaluationScene({timeline: jsonLevel, recordingData:{userTl:jsonLevel.timeline}})
	// }},
	// {id: 'splashy', sceneFactory: ()=>new SplashScene()},
	// {id: 'nose', sceneFactory: ()=>new ProvarScene()},	
	{id: 'intro', sceneFactory: ()=>new TextScene({text:"Girona", emoji:"💗"})},	
	{id: 'girona', sceneFactory: ()=>new GameScene({midiLevelPath: 'girona'})},
	{id: 'ev', sceneFactory: (args:any)=>new EvaluationScene(args)},
	{id: 'intro_2', sceneFactory: ()=>new TextScene({text:"Frère Jacques!", emoji:"😴?🔔"})},	
	{id: 'frere_level', sceneFactory: ()=>new GameScene({midiLevelPath: 'frere'})},
	{id: 'ev2', sceneFactory: (args:any)=>new EvaluationScene(args)},
	{id: 'fcb_intro', sceneFactory: ()=>new TextScene({text:"mario", emoji:"⭐"})},	
	{id: 'mario_level', sceneFactory: ()=>new GameScene({midiLevelPath: 'mario'})},
	{id: 'ev3', sceneFactory: (args:any)=>new EvaluationScene(args)},
	{id: 'fcb_intro', sceneFactory: ()=>new TextScene({text:"fcb?", emoji:"🟥🟦"})},	
	{id: 'fcb_level', sceneFactory: ()=>new GameScene({midiLevelPath: 'fcb'})},
	{id: 'ev4', sceneFactory: (args:any)=>new EvaluationScene(args)},
	{id: 'intro', sceneFactory: ()=>new TextScene({text:"Somewhere?", emoji:"🆙🌈"})},	
	{id: 'somewhere_level', sceneFactory: ()=>new GameScene({midiLevelPath: 'somewhere'})},
	
	
	{id: 'ev_2', sceneFactory: (args:any)=>new EvaluationScene(args)},
	// {id: 'well_played!', sceneFactory: ()=>new TextScene({text:"Yuhu!", emoji:"🏆"})},
	// {id: 'level_2', sceneFactory: ()=>new GameScene({jsonLevelPath: "level_0"})},
	// {id: 'intro', sceneFactory: ()=>new TextScene("Part 2")},
	// {id: 'level_2', sceneFactory: ()=>new GameScene()},
	{id: 'outro', sceneFactory: ()=>new TextScene({text:"The end :D",})}
];
// gamePipeline.filter(p=>p.id.includes("level"))
// V8 change: App initialization is now async
(async () => {
	await Settings.init()
	
	// await app.init({
	// 	canvas: document.getElementById("pixi-canvas") as HTMLCanvasElement,
	// 	resolution: window.devicePixelRatio || 1,
	// 	autoDensity: true,
	// 	backgroundColor: 0x6495ed,
	// 	width: 640,
	// 	height: 480,
	// 	hello: true // Added so you can see what renderer are you using
	// });
	// await Assets.init({ manifest: manifest });
	// await Assets.loadBundle(["splash"], ()=>{console.log("load splash!")})
	// V8 change: You MUST load first. You can't use the `from` method without loading first.
	// await Assets.load("sprites/logo.png")
	const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	const screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	// const sceny: Scene = new Scene(app.screen.width, app.screen.height);
	let pipeline = [] as any
	if (Settings.jsonLevel || Settings.jsonLevelPath || Settings.midiLevelPath){
		pipeline = [
			...loaderPipeline,
			{id: 'custom_level', sceneFactory: ()=>new GameScene({jsonLevel: Settings.jsonLevel, jsonLevelPath: Settings.jsonLevelPath, midiLevelPath: Settings.midiLevelPath})},
			{id: 'ev_2', sceneFactory: (args:any)=>new EvaluationScene(args)},
		]
	}else{
		pipeline = gamePipeline
	}
	await Manager.init([
		...pipeline
	],screenWidth, screenHeight);

	// We no longer need to tell the scene the size because we can ask Manager!
	// load as many levels as you wish
	// nomes crear loady ya nem cardats
	// const loady: SplashScene = new LoaderScene();
	// const gamy: SplashScene = new GameScene();
	
	Manager.start();
})()


/*
TODO:
 - mouse vs tap
 - nivells as json, infinite
 - nivells: load each during stage Text
 - stage Text  & timeout
 - grid & music
 - events magic (touchId)
 - timeline and autoplay
*/