import { Container, ContainerOptions, Graphics } from "pixi.js";
import {ScrollBox} from '@pixi/ui'
import { Button, ButtonOptions } from "./Button";
import { Settings } from "../Settings";
import { Grid } from "./Grid";
import { GameMode } from "../scenes/GameScene";
import { subscribeAndInit } from "../utils/state";


type TimelineOptions = {
    jsonLevel: any,
    width: number,
    height: number,
    // onSublevelChange?: (newSublevel) => void,
    playTimeline?: (start_at: number, end_at: number) => void
    changeSublevelSelected?: (newSublevel:number)=>void
    changeSublevel?: any
}
class TimelineRect extends Button {
    constructor(args: ContainerOptions & ButtonOptions) {
        super({...args, isButton: false})
    }
}

const getScales = ({ minNote, maxNote, minT, maxT }, width, height) => {
    const totalT = Math.max(maxT - minT, 1) // +1 ? 
    // console.log(maxT, minT)
    // +1 ? 
    const totalNote = Math.max(maxNote - minNote, 1) + 1
    let scaleT = (x) => (x) * (width / totalT)
    let scaleNote = (x) => height - (1 + x - minNote) * (height / totalNote)
    return { scaleT, scaleNote, totalT, totalNote }
}
// const getScaler = ({ minNote, maxNote, minT, maxT }, width, height) => {
//     const totalNote = Math.max(maxNote - minNote, 1) + 1
//     const { scaleT, scaleNote } = getScales({ minNote, maxNote, minT, maxT }, width, height)
//     return (e) => {
//         return { ...e, x: scaleT(e.start_at), width: scaleT(e.end_at - e.start_at), y: scaleNote(e.note), height: (height / totalNote) }
//     }
// }

export class Timeline extends Container {
    sublevels: Container[]
    scrollbox: ScrollBox
    // toneTimeline: Tone.Timeline<{time:number, duration:number, value:number}>
    constructor(args: (ContainerOptions & TimelineOptions)) {
        let { changeSublevel, changeSublevelSelected, playTimeline, jsonLevel, width, height, ...containerArgs } = args
        super({ width, height, ...containerArgs })
        this.eventMode = 'dynamic'
        this.sublevels = []
        const background = new Graphics()
        background.rect(0, 0, width, height)
        background.fill({ color: Settings.timelineBackgroundColor })
        // let heightTimeline = height*0.8;
        // let heightKeyframes = height-heightTimeline;

        const { minNote, maxNote, maxT } = jsonLevel.timeline.reduce(({ minNote, maxNote, minT, maxT }, { note, start_at, end_at }) => {
            if (minNote == null || note < minNote) minNote = note
            if (maxNote == null || note > maxNote) maxNote = note
            if (minT == null || start_at < minT) minT = start_at
            if (maxT == null || end_at > maxT) maxT = end_at
            return { minNote, maxNote, minT, maxT }
        }, { minNote: null, maxNote: null, maxT: null, minT: null })
        const timelineWidth = Math.max(jsonLevel?.breaks.length,4)*width/4
        const sequenceHeight = height/4
        const { scaleT } = getScales({ minNote, maxNote, minT: 0, maxT }, timelineWidth, sequenceHeight)
        // const scale = getScaler({ minNote, maxNote, minT: 0, maxT }, timelineWidth, timelineHeight)
        // let containerTimeline = new Container({x:0, y:0, width:timelineWidth, height})
        // let containerSequence = new Container({x:0, y:height-sequenceHeight, width:timelineWidth, height: sequenceHeight})
        // containerTimeline.addChild(containerSequence)
        // jsonLevel.timeline.map((e) => {
        //     // return rect
        //     // background.addChild(rect)
        //     containerSequence.addChild(rect)
        //     return rect
        // })
        // background.addChild(containerTimeline)
        
        // this.scrollbox.scroll
        
        this.scrollbox = new ScrollBox({
            width,
            height,
            type: "horizontal",
            background: "white",
            items: [
            //     ...sublevelsScrollSpies
                // containerTimeline
            ],
        })
        // sino hi ha breaks creemlos
        // let sublevelsScrollSpies: Graphics[] = []
        if (jsonLevel.breaks) {
            const gridHeight = height-sequenceHeight
            // console.log(jsonLevel.breaks)
            let updateBreakpoints = (sublevel: number) => {
                this.sublevels.forEach(l => {
                    l.destroy()
                })
                // canviar
                // this.scrollbox?.scrollTo(sublevel)
                // breaks with notes
                jsonLevel.breaks.forEach((b, i) => {
                    // let x0 = scaleT(b.start_at)
                    // let scrollSpy = new Graphics({x:x0, y:0, width: 0, height: 0})
                    // sublevelsScrollSpies.push(scrollSpy)
                    // let xF = scaleT(b.end_at)
                    let sublevelWidth = scaleT(b.end_at-b.start_at)*0.95
                    let sublevelContainer = new Container({x:0, y:0, width: sublevelWidth, height})
                    // select better pk els silencis tmb compten
                    let allNotes = jsonLevel.timeline.filter(e=>{
                        return e.start_at>= b.start_at && e.start_at<b.end_at
                    })
                    // let notesWithSilence = []
                    // let latestEndAt = null
                    // allNotes.forEach(n=>{
                    //     if (latestEndAt!=null){
                    //         latestEndAt
                    //     }
                    // })
                    allNotes.forEach((e, i, filteredTl)=>{
                        let scaled = {
                            x: i*(sublevelWidth/filteredTl.length),//*scaleT(e.start_at-b.start_at),
                            width: 0.96*(sublevelWidth/filteredTl.length), 
                            y: height-sequenceHeight,//scaleNote(e.note), 
                            height: sequenceHeight//(height / totalNote)
                        }
                        let rect = new TimelineRect({ ...scaled, color: Settings.MidiToColor(e.note), height: sequenceHeight })
                        sublevelContainer.addChild(rect)       
                    })
                    // console.log(jsonLevel.timeline.filter(e=>{
                    //     return e.start_at>= b.start_at && e.start_at<b.end_at
                    // }))
                    // let rect = new TimelineRect({ x:0, y:height-sequenceHeight, width:100, color: 'red', height: sequenceHeight })
                    // sublevelContainer.addChild(rect)
                    // console.log(sublevelContainer.position, 'position')
                    const playTune = ()=>{
                        if (playTimeline){
                            playTimeline(b.start_at, b.end_at)
                        }
                    }
                    // sublevelContainer.x = x0
                    // sublevelContainer.y = 0
                    if (i == sublevel) {
                        // TODO: la infinita scroll timeline es converteix en breakpointssss
                        // this.scrollbox.scrollTo
                        // interrogant
                        let rectC = new Button({
                            text: '🔉', color: "grey",
                            alpha: 0.8,
                            // margin:width*0.1,
                            x: 0, y: 0,
                            height: gridHeight,
                            width: sublevelWidth ,
                        })
                        const changeTextButton = (mode: GameMode)=>{
                            if (mode == GameMode.Playing){
                                rectC.changeText('🔉')
                            }else{
                                rectC.changeText('▶️')
                            }
                        }
                        subscribeAndInit(globalThis.GameState, 'mode', changeTextButton)
                        subscribeAndInit(globalThis.GameState, 'sublevelSelected', (newSublevel)=>newSublevel==i?rectC.button.tint = 'lightblue':rectC.button.tint = 'white')
                        rectC.eventMode = 'dynamic'
                        // if (changeSublevel != null) {
                        rectC.on("pointerdown", (_e) => {
                            if (globalThis.GameState.mode == GameMode.Practicing)playTune()
                            if (changeSublevelSelected)changeSublevelSelected(i)
                        })
                        // }
                        sublevelContainer.addChild(rectC)
                    }else if (i < sublevel) {
                        let rectC = new Graphics()
                        rectC.rect(0, 0, sublevelWidth, gridHeight)
                        rectC.fill({ color: 'grey', alpha: 0.5 })
                        rectC.eventMode = 'dynamic'
                        rectC.on("pointerdown", (_e) => {
                            if (globalThis.GameState.mode == GameMode.Practicing)playTune()
                            if (changeSublevelSelected)changeSublevelSelected(i)
                        })
                        subscribeAndInit(globalThis.GameState, 'sublevelSelected', (newSublevel)=>newSublevel==i?rectC.tint = 'lightblue':rectC.tint = 'grey')
                        sublevelContainer.addChild(rectC)
                        // merdos TODO start at end at
                        let notes = jsonLevel.timeline.filter(({ start_at }) => start_at >= b.start_at && start_at < b.end_at)
                        if (notes.length>0 && notes[notes.length-1]){
                            // hem de tallar el meme per end_at
                            // notes[notes.length-1].end_at > end_at
                        }
                        // Math.min(notes.map({start_at_seconds})=>start_at_seconds)
                        // GRID id to position
                        // let points = notes.map(n=>{
                        //     let found = jsonLevel.grid.filter(({id})=>id==n.id)
                        //     if (found.length){
                        //         return found
                        //     }
                        // }).filter(d=>d)
                        // let scaledPoints = scalePoints(points, {x:x0,y:0,width:xF-x0,height}, true)

                        // scaledPoints.forEach(({x,y})=>{
                        //     let rectD = new Graphics()
                        //     // no va!
                        //     // rectD.lineTo
                        //     rectD.beginPath()
                        //     rectD.moveTo(x,y)
                        //     rectD.lineTo(x+100, y+100)
                        //     // rectD.rect(x,y, 10,10)
                        //     // rectD.fill({color:'red', alpha: 0.5})
                        //     // rectD.stroke({width:2, color:'white'});

                        //     background.addChild(rectD)
                        // })
                        // let geometries = geometryBreaks.map(b=>b=>)
                        // let {start_at: geoStartAt, end_at:geoEndAt} = b.geometryBreaks[0]
                        let notesGeometry = notes //jsonLevel.timeline.filter(({start_at, end_at})=>start_at>=geoStartAt && end_at<=geoEndAt)
                        let notesIds = notesGeometry.map(n => n.id)
                        let subGrid = jsonLevel.grid.filter(d => notesIds.includes(d.id))
                        let polygon = new Grid({ timeline: notesGeometry, grid: subGrid, width: sublevelWidth, height: gridHeight, x: 0, y: 0, zIndex: 10, labels:false })
                        // if (b.show){
                        sublevelContainer.addChild(polygon)
                    }else{
                        let rectC = new Graphics()
                        rectC.rect(0, 0, sublevelWidth, gridHeight)
                        rectC.fill({ color: 'grey', alpha: 0.5 })
                        sublevelContainer.addChild(rectC)
                    }
                    this.sublevels.push(sublevelContainer)
                    // containerTimeline.addChild(sublevelContainer)
                    this.scrollbox.addItem(sublevelContainer)
                    // sublevelsScrollSpies.push(sublevelContainer)
                    // items.push(sublevelContainer)
                    // }
                })
                this.scrollbox.scrollTo(sublevel+1)
            }
            subscribeAndInit(globalThis.GameState, 'sublevel', updateBreakpoints)
            // subscribeKey(globalThis.GameState, 'sublevel', updateBreakpoints)//
        }
        // this.scrollbox?.scrollTo(1)
        // let item = new Text({text: 'hola', })
        // items.forEach(it=>{
        //     // it.rotation +=(Math.PI/2)
        //     it.position.set(0,0)
        // })
        
        // this.scrollbox.pivot.set(0,0)
        // this.scrollbox.rotation -= Math.PI/2
        // this.scrollbox.position.set(0, height)
        // this.scrollbox.rotation = Math.PI
        // const items2=[
        //             new Graphics().drawRect(0, 0, height, 50).fill('red'),
        //             new Graphics().drawRect(0, 0, height, 50).fill("blue"),
        //             new Graphics().drawRect(0, 0, height, 50).fill('red'),
        //             new Graphics().drawRect(0, 0, height, 50).fill("blue"),
        //             new Graphics().drawRect(0, 0, height, 50).fill('red'),
        //             new Graphics().drawRect(0, 0, height, 50).fill("blue"),
        //             new Graphics().drawRect(0, 0, height, 50).fill('red'),
        //             new Graphics().drawRect(0, 0, height, 50).fill("blue"),
        //         ]
        // this.scrollbox = new Scrollbox({boxHeight: height, boxWidth: width})
        background.addChild(this.scrollbox)
        // this.scrollbox.position.set(0,0)
        // items2.map(it=>this.scrollbox.content.addChild(it))
        // this.scrollbox.update()
        
        // jsonLevel.keyframes = 
        this.addChild(background)
        // breakpoints
    }
}
