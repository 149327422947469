import { FederatedPointerEvent } from "pixi.js"

export class PointerManager {
    static touchesDict: Record<string, string>
    static keysAvailable: string[]
    constructor(){}
    public static getTouchKey(e: FederatedPointerEvent){
        let touchId = e.pointerType==="touch"?e.pointerId:"mouse"
        if(!PointerManager.touchesDict){
            PointerManager.touchesDict = {}
            PointerManager.keysAvailable = [0,1,2,3,4,5,6,7,8].map(n=>`${n}`)
        }
        if (PointerManager.touchesDict && PointerManager.touchesDict[touchId]==null){
            if (PointerManager.keysAvailable.length>0){
                let newId = PointerManager.keysAvailable.pop()!
                if(newId!=null){
                    PointerManager.touchesDict[touchId]=newId
                }else{
                    console.log("ERROR: available but not poppin")
                    // console.log(PointerManager.touchesDict, PointerManager.keysAvailable, "didnt find id")
                }
            }else{
                console.log("ERROR: out of touches")
            }
            
        }
        // console.log("GETTING", touchId, PointerManager.touchesDict[touchId])
        return PointerManager.touchesDict[touchId]
    }
    public static freeTouchKey(e: FederatedPointerEvent){
        let touchId = e.pointerType==="mouse"?"mouse":e.pointerId
        // if (touchId==0)debugger
        if(PointerManager.touchesDict){
            let key = PointerManager.touchesDict[touchId]
            // console.log(PointerManager.touchesDict, PointerManager.touchesDict[touchId], touchId)
            if (key!=null){
                PointerManager.keysAvailable.push(key)
                delete PointerManager.touchesDict[touchId]
            }
            return key
        }
        return
    }
}