import { Container, Graphics, Ticker, Text } from "pixi.js";
import { IScene, Manager } from "../Manager";
// import { Player } from "../components/Player";
// import { LoaderScene } from "./LoaderScene";
export type EvaluationData = {
    pass?: boolean,
}
// const levenshteinDistance = (_original, user)=>{
//     if (user.length >= 1){
//         return 0
//     }
//     return 10
// }
export class EvaluationScene extends Container implements IScene {
    public popup = true
    // for making our loader graphics...
    constructor({pass}:EvaluationData) {
        super();

        // let timeline =  Assets.get('level_0')
        // let recordingData
        // console.log(tl)
        let win = pass
        let emoji, text, buttonOnClick;
        
        let invisibleButton = new Graphics();
        // right order! rect -> fill
        invisibleButton.rect(0, 0, Manager.width, Manager.height);
        invisibleButton.fill({color:"white", alpha:0.8});
        invisibleButton.eventMode = "dynamic"
        
        if (win){
            emoji = '❤️'
            text = 'Nice!'
            buttonOnClick = ()=>Manager.nextScene()
        }else{
            emoji = '😣'
            text = 'Failed!'
            buttonOnClick = ()=> Manager.previousScene()
        }
        invisibleButton.once("pointerdown", ()=>{
            // console.log("pointerdown!")
            buttonOnClick()
        })
        this.addChild(invisibleButton);

        if (text){
            const textC = new Text({text})
            textC.anchor.x = 0.5
            textC.anchor.y = 0.5
            textC.position.x = Manager.width/2
            textC.position.y = 2*Manager.height/3
            this.addChild(textC); 
        }
        if (emoji){
            const textEmoji = new Text({text:emoji})
            textEmoji.scale.set(3)
            textEmoji.anchor.x = 0.5
            textEmoji.anchor.y = 0.5
            textEmoji.position.x = Manager.width/2
            textEmoji.position.y = Manager.height/3
            // text.position.x = Manager.width
            this.addChild(textEmoji);
        }
        
    }
    
    public update(_ticker: Ticker): void {
        // To be a scene we must have the update method even if we don't use it.
    }
}