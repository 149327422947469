import { Container, ContainerOptions, Graphics } from "pixi.js";

import { TileEventCallback, TileGraphics } from "./Tile"
import { Settings } from "../Settings";
import { NoteType } from "../scenes/GameScene";
import { Arc } from "./Arc";
import { GridSettings } from "./GridSettings";
// controlar els setters de Grid pk me lestan colant
export const DIRECTIONS = {
    SE: { x: 0.5, y: -0.5 },
    S: { x: 0, y: -1 },
    SO: { x: -0.5, y: -0.5 },
    O: { x: -1, y: 0 },
    NO: { x: -0.5, y: 0.5 },
    N: { x: 0, y: 1 },
    NE: { x: 0.5, y: 0.5 },
    E: { x: 1, y: 0 }
};
type GridOptions = {
    grid,
    labels?: boolean,
    width:number, 
    height:number,
    timeline?: NoteType[] ,
    // displayLabels:boolean
    onActivateTile?: TileEventCallback
    onDeactivateTile?: TileEventCallback
}

export class Grid extends Container {
    public cells: Record<string, TileGraphics>
    public numCols: number
    public numRows: number
    public heightTile: number
    // public activeTiles: Record<string, TileGraphics>
    // private scene: GameScene
    public minNote:number
    public maxNote:number
    constructor(args: (ContainerOptions&GridOptions)){
        let {grid, width, height, onActivateTile,onDeactivateTile, labels, timeline, ...containerArgs} = args
        const displayLabels = labels!=null?labels:Settings.labels
        console.log({labels:Settings.labels})
        super({width, height, ...containerArgs})
        // this.scene = scene
        this.eventMode = 'dynamic'
        let cellsInit = JSON.parse(JSON.stringify(grid))
        
        let [xs, ys] = [cellsInit.map(c=>c.x), cellsInit.map(c=>c.y)]
        let [xMax, xMin] = [Math.max(...xs),Math.min(...xs)]
        let [yMax, yMin] = [Math.max(...ys),Math.min(...ys)]
        let addedCols = 1
        let diffX = Math.max((xMax-xMin),0.5)+addedCols
        this.numCols = diffX
        let diffY = Math.max((yMax-yMin),0.5)+addedCols
        this.numRows = diffY
        let heightTile = Math.min(width/diffX,height/diffY)
        let [offsetX, offsetY] = [0,0]
        this.heightTile = heightTile
        let borderX = Math.max(0, width - (this.numCols)*this.heightTile)/2
        let borderY = Math.max(0, height - (this.numRows)*this.heightTile)/2
        // console.log({borderX, width, numCols:this.numCols, numRows:this.numRows, height, borderY, heightTile})
        // orientation
        if (heightTile == width/diffX){
            console.log("sobra vertical", borderX, borderY)
        offsetY += heightTile/2
     offsetX += heightTile/2
        }else if(heightTile == height/diffY){
            // console.log("sobra horitzontal", borderX, borderY)
            //if (Settings.jsonLevelPath == 'simple'){
            offsetX += heightTile/2
            offsetY+= heightTile/2
           // }
        }
        
        // console.log(heightTile, diffX, diffY)
        let cellsObj = cellsInit.map((c)=>{
            // c.id = /*c.id ||*/ //getId(c)
            //c.id
            c.position = {x: (c.x-xMin)*heightTile+offsetX, y: (c.y-yMin)*heightTile+offsetY}   
            c.coords = {x: c.x, y: c.y}
            if (!c.id) c.id = c.note
            delete c.x
            delete c.y         
            return c
        })
        
        let notes = cellsObj.map(c=>c.note)
        this.minNote = Math.min(...notes)
        this.maxNote = Math.max(...notes)
        this.cells = Object.fromEntries(cellsObj.map(c=>[c.id, new TileGraphics({height: heightTile, grid:this, displayLabel: displayLabels, color: GridSettings.getColor(c.note), onActivateTile, onDeactivateTile, ...c})]))
        // this.cells = Object.fromEntries(cellsObj.map(c=>[c.id, new TileGraphics({height: heightTile, grid:this, displayLabel: displayLabels, color: , ...c})]))
        let borderBackground = new Container()
        // console.log(this.cells)
        
        borderBackground.position.x += borderX
        borderBackground.position.y += borderY
        borderBackground.eventMode = 'dynamic'
        
        Object.values(this.cells).map(c=>{
            borderBackground.addChild(c)
        })
        if (timeline){
            let points = timeline.map((n)=>{
                let found = this.cells[n.id]
                if (found){
                    return {x: found.position.x, y: found.position.y}
                }
                return 
            }).filter(d=>d)
            let tlLine = new Arc({points: points as any, closed:false,stroke:{color:'white', alpha: 0.5, width:2}, zIndex:5})
            borderBackground.addChild(tlLine)
            let startingCircle = new Graphics() 
            startingCircle.circle(points[0]?.x!, points[0]?.y!, heightTile*0.1)
            startingCircle.fill({color:'white', alpha:0.5})
            borderBackground.addChild(startingCircle)
            let endingCircle = new Graphics() 
            let size = heightTile*0.2
            endingCircle.rect(points[points.length-1]?.x!-size/2, points[points.length-1]?.y!-size/2, size, size)
            endingCircle.fill({color:'white', alpha:0.5})
            borderBackground.addChild(endingCircle)
            // points[0]
        }
        this.addChild(borderBackground)
        
    }
    enableAllCells(){
        
    }
    disableAllCells(){

    }
    
    
    
}
