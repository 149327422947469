export class Evaluator{
    private constructor(){}
    public static evaluate(tl, userInput, sameLength=false){
        let pass = false
        if ((sameLength && userInput.length===tl.length) || userInput.length>=tl.length){
            let lastNotes = userInput.slice(userInput.length-tl.length)
            pass = tl.every((k,i)=>{
                return k.note === lastNotes[i].note
            })
        }
        return {pass}
    }
}