import { Container, FederatedPointerEvent, Graphics, Ticker } from "pixi.js";
import { IScene, Manager } from "../Manager";
import { PointerManager } from "../utils/PointerManager";
import { Grid } from "../components/Grid";
import { Timeline } from "../components/Timeline";
import { Controls } from "../components/Controls";
import { Player } from "../components/Player";
import {getSecondsTimeline} from '../utils/music'
import { Settings } from "../Settings";
import { TileGraphics } from "../components/Tile";
import { proxy } from 'valtio'
import { subscribeKey } from "valtio/utils";
import { loadJson, loadMidi } from "../utils/io";
import { GridSettings } from "../components/GridSettings";
import { Evaluator } from "../components/Evaluator";
export type RecordingData = {userTl?: NoteType[], start_at?: number, end_at?: number}
export enum GameMode {Recording,Playing,Practicing}
// let jsonLevel = 
export type NoteType = {
    id: string,
    start_at: number,
    end_at?: number,
    note: number
}
export type TimelineType = {
    breaks: {start_at:number, end_at?:number}[]
    timeline: NoteType[]
}
export type ButtonType = {
    emoji: string,
    onClick: ()=>void
}
export enum EventKey {
    NoteOn,
    ChangeState,
    NoteOff
}
export type GameStateType = {
    mode:GameMode, 
    events: EventType[], 
    sublevel?:number, 
    sublevelSelected?:number
}
export enum GameStateKeys {
    Mode = 'mode',
    Events = 'events',
    Sublevel = 'sublevel',
    SublevelSelected = 'sublevelSelected'
}
const getFilteredTimeline = (tl: NoteType[], start_at, end_at)=>{
    // let tl = this.jsonLevel.timeline
    if (start_at!=null){
        tl = tl.filter(tl=>tl.start_at>=start_at)
    }
    if (end_at!=null){
        tl = tl.filter(tl=>tl.start_at!<end_at)
    }
    return tl
}
// TODO pensar com faig que els "slides" (segons els breaks)
// es segueixin uns els altres
// TODO no funciona pause
export type EventType = {key:EventKey,params:any, time: number}
const getTimelineFromEvents = (eventsPlayed)=>{
    let eventsNoteOff = eventsPlayed.filter((e)=> e.key === EventKey.NoteOff && e.params.userInput == true)
    let notesPlayed = eventsNoteOff.map(e=>e.params)
    return notesPlayed
}
export class GameScene extends Container implements IScene {
    public popup = false
    private grid: Grid
    private timeline?: Timeline
    private controls?: Controls
    // private popupCongrats: Container
    private jsonLevel: any
    // public mode: GameMode = GameMode.Practicing
    // private timeline: any[]
    // private currentMode: GameMode
    // public durationTimeline: number
    public lastNoteOff?: EventType
    public activeTiles: Record<string, TileGraphics>
    // public recordingData: {userTl: NoteType[], start_at: number, end_at?: number}|undefined
    public gameState:  GameStateType// Record<string,ButtonType>;
    // public eventsCallbacks: Record<EventKey,((args:any, events:EventType[])=>void)[]>
    public cancelTimeline(){
        console.log("CANCELLING TIMELINE")
        this.stopAll()
        Player.cancelSchedule()
    }
    public modeCallback( {mode:newMode}){
        
        switch(newMode){
            case GameMode.Playing:
                // this.gameState.controlButtons = [
                //     {emoji: "⏸️", onClick:()=>this.togglePlay()} // ▶️⏸️🔴🟥
                // ]
                break
            case GameMode.Practicing:
                // this.gameState.controlButtons = [
                //     {emoji: "▶️", onClick:()=>this.togglePlay()},
                //     {emoji: "🔴", onClick:()=>this.toggleRecord()},
                // ]
                break
            case GameMode.Recording:
                // this.gameState.controlButtons = [
                //     {emoji: "🟥", onClick:()=>this.toggleRecord()},
                // ]
                break
            default:
                break
        }
    }
    public setMode(mode: GameMode){
        this.gameState.mode = mode
        // this.modeCallback(mode)
        this.addEvent(EventKey.ChangeState, {key: GameStateKeys.Mode,mode})
    }
    // public setControlButtons(){

    // }
    // public addEventCallback(key:EventKey, callback:(args:any)=>void){
    //     if (!this.eventsCallbacks[key]){
    //         this.eventsCallbacks[key] = []
    //     }
    //     this.eventsCallbacks[key].push(callback)
    // }
    public changeSublevel(sublevel){
        this.gameState.sublevel = sublevel
        this.addEvent(EventKey.ChangeState, {key: GameStateKeys.Sublevel,sublevel})
    }
    public changeSublevelSelected(sublevel){
        this.gameState.sublevelSelected = sublevel
        this.addEvent(EventKey.ChangeState, {key: GameStateKeys.SublevelSelected,sublevel})
    }
    constructor(params: {jsonLevelPath?:string, jsonLevel?:object, midiLevelPath?:string}) {
        super();
        
        // this.eventsCallbacks = {} as any
        // this.eventsCallbacks.push(this.modeCallback)
        // this.addEventCallback(EventKey.ChangeMode, this.modeCallback.bind(this))
        this.activeTiles = {}
        this.gameState = proxy({
            // controlButtons:[],
            events: [],
            mode: GameMode.Practicing,
            sublevel: undefined,
            sublevelSelected: undefined
        })
        globalThis.GameState = this.gameState
        // subscribeKey(this.gameState,'events', (events)=>{
        //     this.eventsCallbacks.forEach(callback=>{
        //         callback(events)
        //     })
        // })
        // this.currentMode = GameMode.Practicing
        let jsonLevelSettings;
        if (params.midiLevelPath){
            jsonLevelSettings = loadMidi(params.midiLevelPath)
        }else if (params.jsonLevelPath){
            jsonLevelSettings = loadJson(params.jsonLevelPath)
        }else{
            jsonLevelSettings = params.jsonLevel
        }
        this.jsonLevel = jsonLevelSettings // Object.values(jsonLevelSettings.tracks)[0] as object
        
        const fatherBackground = new Graphics()
        fatherBackground.rect(0, 0, Manager.width, Manager.height);
        fatherBackground.fill({ color: 'black', alpha: 0 });
        fatherBackground.eventMode = 'dynamic'
        fatherBackground.once("pointerdown", ()=>{
            // this.controlButtonsProxy["playpause"] = {...this.controlButtonsProxy["playpause"], emoji:":D"}
            console.log("first pointer down!")
            Player.init()
            if (Settings.simpleView){
                this.togglePlay()
            }
        })
        // console.log(Manager.width, Manager.height)
        let tlH = 0.1
        if (!this.jsonLevel.timeline?.length || Settings.simpleView){
            tlH = 0
        }
        let ctrlsW = 0.2
        let boxes = {
            timeline: {width: Manager.width*(1-ctrlsW), height: Manager.height*tlH},
            controls: {width: Manager.width*ctrlsW, height: Manager.height*tlH, x: Manager.width*(1-ctrlsW)},
            grid: {width: Manager.width, y: Manager.height*tlH, height: Manager.height*(1-tlH)}
        }
        GridSettings.init(this.jsonLevel.grid)
        // this.durationTimeline = 0
        if (this.jsonLevel.timeline?.length){
            // subscribe(this.gameState,()=>console.log(this.gameState, 'changed'))
            this.controls = new Controls({...boxes.controls, toggleRecord: this.toggleRecord.bind(this), togglePlay: this.togglePlay.bind(this)})
            this.jsonLevel.timeline = getSecondsTimeline(this.jsonLevel.timeline, this.jsonLevel.settings||Settings)
            // this.durationTimeline = Math.max(...this.jsonLevel.timeline.map(({end_at_seconds})=>end_at_seconds))
            this.timeline = new Timeline({
                playTimeline:(...args)=>this.playTimelineForce(...args), 
                changeSublevelSelected: (newSublevel)=>this.changeSublevelSelected(newSublevel),
                jsonLevel: this.jsonLevel, 
                ...boxes.timeline 
            })
            this.changeSublevel(0)
            this.changeSublevelSelected(0)
            if (!Settings.simpleView){
                fatherBackground.addChild(this.timeline)
                fatherBackground.addChild(this.controls)
            }
            subscribeKey(this.gameState, GameStateKeys.Events, (events)=>{
                // if (this.gameState.sublevelSelected!=null){
                // console.log(JSON.stringify(events))
                let lastNoteOff = events.filter(e=>e.key==EventKey.NoteOff).at(-1)
                if (lastNoteOff){
                    if (!this.lastNoteOff) this.lastNoteOff = lastNoteOff
                    if (this.lastNoteOff != lastNoteOff){
                        this.lastNoteOff = lastNoteOff
                        let indexSublevel = events.findLastIndex(e=>e.key==EventKey.ChangeState && e.params.key==GameStateKeys.Sublevel)
                        let indexSublevelSelected = events.findLastIndex(e=>e.key==EventKey.ChangeState && e.params.key==GameStateKeys.SublevelSelected)
                        if (indexSublevel!=-1 && indexSublevelSelected!=-1){
                            let sublevel = events[indexSublevel]?.params?.sublevel
                            let sublevelSelected = events[indexSublevelSelected]?.params?.sublevel
                            if (sublevel == sublevelSelected && sublevel<this.jsonLevel.breaks.length){
                                let {start_at, end_at} = this.jsonLevel.breaks[sublevel]
                                let tl = getFilteredTimeline(this.jsonLevel.timeline, start_at, end_at)
                                // let notes = tl.map(({note})=>note)
                                let indexMax = Math.max(indexSublevel, indexSublevelSelected)
                                let eventsPlayed = events.slice(indexMax)
                                // let eventsNoteOff = eventsPlayed.filter((e)=> e.key === EventKey.NoteOff && e.params.userInput == true)
                                let notesPlayed = getTimelineFromEvents(eventsPlayed) // eventsPlayed.map(e=>e.params)
                                // let notesPlayed = eventsPlayed.map(({params})=>params.note)
                                // console.log(JSON.stringify(eventsPlayed))
                                
                                if (Evaluator.evaluate(tl.filter(n=>n.note), notesPlayed).pass){
                                    // console.log("played same notes!", notes, notesPlayed)
                                    if (this.jsonLevel.breaks.length>sublevel){
                                        this.changeSublevel(sublevel+1)
                                        this.changeSublevelSelected(sublevelSelected+1)
                                    }
                                }else{
                                    // console.log("didnt match!", notes, notesPlayed)
                                }
                            }
                        }
                    }
                }
                // if (events.at(-1)?.key==EventKey.NoteOff){
                    // events from last change of sublevels sublevel
                    // events.filter(e=>e.key=='')
                // }
            })
        }
        
        // this.grid = new Grid({jsonLevel, width:Manager.width, height:Manager.height})
        this.grid = new Grid({ /*timeline: this.jsonLevel.timeline ,*/ grid: this.jsonLevel.grid, onActivateTile: this.onActivateTile, onDeactivateTile: this.onDeactivateTile, ...boxes.grid })
        fatherBackground.on("pointerup", (e) => {
            // console.log("pointerup outside!")
            this.onDeactivateTile(e)
        })
        fatherBackground.on("pointerleave",(e)=>{
            // console.log("pointerleave game!")
            this.onDeactivateTile(e)
        })
        fatherBackground.addChild(this.grid)
        this.addChild(fatherBackground)
        // despres de crear les subscriptions!
        this.modeCallback({mode:GameMode.Practicing})
    }
    public playTimelineForce(start_at?:number, end_at?:number){
        this.setMode(GameMode.Playing)
        this.cancelTimeline()
        this.playTimeline(start_at, end_at).then(_d=>{
            this.setMode(GameMode.Practicing)
        })
    }
    public togglePlay(start_at?:number, end_at?:number){
        if (this.gameState.mode!=GameMode.Playing){
            this.playTimeline(start_at, end_at).then(_d=>{
                this.setMode(GameMode.Practicing)
            }).catch(_reason=>{
                this.setMode(GameMode.Practicing)
            })
            this.setMode(GameMode.Playing)
        }else{
            this.cancelTimeline()
            this.setMode(GameMode.Practicing)
        }
        console.log("PLAY/PAUSE!")
    }
    public recordUser(){
        this.gameState.events
        // this.gameState.recordingData = {userTl:[]}
        // return Player.schedule(()=>{
        //     console.log("congrats!")
        // }, this.durationTimeline)
        // this.recordingData = {userTl:[], start_at: Player.now()}
        // return new Promise<void>((resolve, _reject)=>{
            
            // setTimeout(()=>{
            //     Manager.nextScene()
            //     resolve()
            // },this.durationTimeline*1000)
        // })
        
    }
    public cancelRecording(){
        this.setMode(GameMode.Practicing)
    }
    public toggleRecord(){
        if (this.gameState.mode != GameMode.Recording){
            // if (this.gameState.recordingData!=null) this.gameState.recordingData!.start_at = Player.now()
            // this.setMode(GameMode.Practicing)
            // Manager.nextScene(this.gameState.recordingData)
            this.setMode(GameMode.Recording)
            this.recordUser()
            // .then(_d=>{
            //     this.gameState.mode = GameMode.Practicing
            //     // analyse result!
            // }).catch(_reason=>{
            //     this.gameState.mode = GameMode.Practicing
            // })
        }else{
            // this.gameState.recordingData!.end_at = Player.now()
            let indexRecording = this.gameState.events.findLastIndex(e=>e.key==EventKey.ChangeState && e.params.key==GameStateKeys.Mode && e.params.mode==GameMode.Recording)
            let timelineUser = getTimelineFromEvents(this.gameState.events.slice(indexRecording))
            this.setMode(GameMode.Practicing)
            Manager.nextScene(Evaluator.evaluate(this.jsonLevel.timeline.filter(n=>n.note), timelineUser, true)) 
        }
    }
    public stopAll(){
        Object.keys(this.activeTiles).forEach((k:string)=>{
            this.stop(k)
        })
    }
    public play(id:string, key: string, eventType?:string, whoisplaying?:string){
        if (!Player.playerInit) Player.init()
        // if ()
        if (!key){
            // inventat una key
            return
        }
        if (!(this.gameState.mode != GameMode.Playing || key.includes('scheduled' ))){
            return
        }
        let cell = this.grid.cells[id]
        if (cell){
            if (this.activeTiles[key]){
                // this.activeTiles[key].deactivate(key)
                // no parem player pk ara posarem unaltre fals, si que el parem
                if (eventType=='pointerenter'){
                    this.stop(key)
                }
            }
            this.activeTiles[key] = cell
            Player.play(key, cell.note)
            cell.activate(key, whoisplaying)
            // console.log("NOTEONNN EHH!", key)
            this.addEvent(EventKey.NoteOn,{
                id: cell.id,
                note: cell.noteMidi,
                key,
                userInput: !key.includes('scheduled')
            })
        }
        // console.log(cell)
    }
    public stop(key: string, ){
        // debugger
        let cell = this.activeTiles[key]
        if (cell){
            if (cell.isActive()){
                this.addEvent(EventKey.NoteOff,{
                    id: cell.id,
                    note: cell.noteMidi,
                    key,
                    userInput: !key.includes('scheduled')
                })
            }
            cell.deactivate(key)
            Player.stop(key)
            // console.log("NOTEOFFF EHH!", key)
            
        }else{
            console.log("deactivated already", key)
        }
    }
    public addEvent(key: EventKey, params:any={}){
        this.gameState.events = [...this.gameState.events,{key, params, time: Player.now() }] // .push()
        // console.log(this.eventsCallbacks[key])
        // if (this.eventsCallbacks[key]){
        //     this.eventsCallbacks[key].forEach(c=>{
        //         c(params, this.gameState.events)
        //     })
        // }
    }
    
    public playTimeline(start_at?:number, end_at?:number){
        // Player.scheduleToneTimeline(this.timeline.tl, start_at, end_at, )
        let tl = getFilteredTimeline(this.jsonLevel.timeline, start_at, end_at)
        // let minSec = Math.min(...tl.map(({start_at_seconds})=>start_at_seconds))
        let newTl = tl.filter(e=>e.note!=null).map(e=>({...e, 
            onStart: ()=>this.play(e.id, `scheduled-${e.id}`, undefined, 'player' ),
            onEnd: ()=>this.stop(`scheduled-${e.id}`),
            // start_at_seconds: e.start_at_seconds-minSec
            // end_at_seconds: e.end_at_seconds-minSec
        })) 
        return Player.scheduleTimeline(newTl as any, 0, {bpm: this.jsonLevel.settings.bpm,  ticks_per_beat: this.jsonLevel.settings.ticks_per_beat})
    }
    update(_ticker: Ticker): void {
        // console.log(ticker)
        // throw new Error("Method not implemented.");
    }
    public onActivateTile = (e:FederatedPointerEvent, tile: TileGraphics, eventType?:string)=>{
        let key =  PointerManager.getTouchKey(e)
        if (key!=null){
            this.play(tile.id, key, eventType)
        }
        
    }
    public onDeactivateTile = (e:FederatedPointerEvent)=>{
        let key = PointerManager.freeTouchKey(e)
        // let tile = this.grid[]
        if (key) {
            this.stop(key)
        } else {
            // console.log("Error! No key!")
        }
    }
}