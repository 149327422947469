import { Settings } from "../Settings"
import { NoteType } from "../scenes/GameScene"
// import * as d3 from "d3-scale"
export class GridSettings {
    public static minNote: number
    public static maxNote: number
    private constructor() { /*this class is purely static. No constructor to see here*/ }
    public static init(cells: NoteType[]){
        let notes = cells.map(c=>c.note)
        GridSettings.minNote = Math.min(...notes)
        GridSettings.maxNote = Math.max(...notes)
    }
    public static getColor(note:number){
        return Settings.MidiToColor(note)
        // if (Settings.startTileColor && Settings.endTileColor){
        //     return d3.scaleLinear([GridSettings.minNote, GridSettings.maxNote], [Settings.startTileColor,Settings.endTileColor])(note)
        // }
        // return Settings.tileColor
    }
}