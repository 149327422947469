import jsonUrl from 'json-url'
import { completeDict } from './utils/color';
// import { Color } from 'pixi.js';
function parseParams(params: Record<string,string>) {
    // const params = new URLSearchParams(url);
    const result = {};

    for (const [key, value] of Object.entries(params)) {
        // Check for boolean values
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
            result[key] = value.toLowerCase() === 'true';
        } 
        // Check for numeric values
        else if (!isNaN(value as unknown as number) && value.trim() !== '') {
            result[key] = +value;
        } 
        // Default to string
        else {
            result[key] = value;
        }
    }

    return result;
}
let Colors  = {
    0:'#ff3d3f',
    2:'#f49349',
    4:'#f6c556',
    5:'#00ed39',
    7: '#00f0ef',
    9:'#5e6dff',
    11:'#e67bfc'
}

export class Settings {
    public static tempo: number = 500000
    public static bpm: number = 70
    public static ticks_per_beat: number = 480
    public static backgroundColor: string = 'transparent'
    public static tileColor: string = "#e9d9bf"
    public static startTileColor: string|undefined = "blue"
    public static endTileColor: string|undefined  = "red"
    public static timelineBackgroundColor: string = 'transparent'
    public static timelineColor: string= "grey"
    public static labels: string|null=null
    public static jsonLevel: object|undefined
    public static jsonLevelPath: string|undefined
    public static midiLevelPath: string|undefined
    public static simpleView: boolean = false
    public static NoteToColor: Record<string,string>
    public static MidiToColor = (midi:number)=>{
        let note = midi%12
        let baseColor = this.NoteToColor[note]
        // let octave = Math.floor(midi/12)-5
        // let colorHsluv = new Hsluv();
        // colorHsluv.hsluv_h = note*(360/12)
        // colorHsluv.hsluv_s = 100
        // colorHsluv.hsluv_l = (0.65+(0.15)*octave)*100
        // lighten or darken depending on octave
        // let color;
        // if (octave>0){
        //     color = changeTint(new Color(baseColor), {brighter: octave})
        // }else{
        //     color = changeTint(new Color(baseColor), {darker: Math.abs(octave)})
        // }
        // colorHsluv.hsluvToHex()
        return baseColor
    }
    private constructor() {}
    static async init(args?:object){
        if (!args) args = {}
        let splitV = window.location.search.split("?")
        this.NoteToColor = completeDict(Colors)
        if (splitV.length>1){
            let objStrings = Object.fromEntries([...new URLSearchParams(window.location.href.split("?")[1])])
            let {encoded, ...otherObjStrings} = objStrings
            console.log(objStrings)
            let encodedObj = {}
            if (objStrings['encoded']){
                let result = await jsonUrl('lzw').decompress()
                encodedObj = result||{}
            }
            args = {...args,...encodedObj, ...parseParams(otherObjStrings)}
            
            // if (objStrings && objStrings[key]){
            //     let v = await jsonUrl('lzw').decompress(objStrings[key])
            //     // .then((v)=>{
            //         args = {}
            //     // })
            // }
        }
        Object.entries(args).map(([k,v])=>{
            Settings[k] = v
        })
    }

}