import { Container, ContainerOptions, Graphics, } from "pixi.js";
import { GameMode } from "../scenes/GameScene";
import { Button } from "./Button";
import { subscribeAndInit } from "../utils/state";
// import { subscribe } from "valtio";

type TimelineOptions = {
    width:number, 
    height:number,
    toggleRecord: ()=>void,
    togglePlay: ()=>void
}
type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};
export class Controls extends Container{
    // private scene: GameScene
    // private buttons: Button[]
    // private
    // private playPauseButton: Button
    // private recordButton: Button
    // public unsubscribe: ()=>void;
    // public playing: boolean = false
    constructor(args: (ContainerOptions&TimelineOptions)){
        let {width, height, toggleRecord,togglePlay, ...containerArgs} = args
        super({width, height, ...containerArgs})
        this.eventMode = 'dynamic'
        const ModeToRecordButton:  EnumDictionary<GameMode, string|null> = {
            [GameMode.Recording]: "🟥",
            [GameMode.Playing]: "🔴",
            [GameMode.Practicing]: "🔴",
        }
        const ModeToPlayButton:  EnumDictionary<GameMode, string|null> = {
            [GameMode.Recording]: null,
            [GameMode.Playing]: "⏸️",
            [GameMode.Practicing]: "▶️",
        }
        // this.scene = scene
        // this.buttons = []
        // let subscriptionFunc = (buttons: ButtonType[])=>{
        //     this.buttons = buttons.map((buttonSkeleton, i, buttonList)=>{
        //         let b = new Button({
        //             text:buttonSkeleton.emoji,  
        //             color: "transparent",
        //             alpha:1,
        //             // margin:width*0.1,
        //             x:i*width/buttonList.length, y:0,
        //             height: height,
        //             width: width/buttonList.length,
        //             // width: width*0.5, height: height*0.8
        //         })
        //         b.on("pointerdown",()=>{
        //             buttonSkeleton.onClick()
        //         })
        //         return b
        //     })
        //     // console.log(this.buttons)
        //     this.removeChildren();
        //     this.buttons.forEach(c=>this.addChild(c))
        // }
        // display sublevel etc
        subscribeAndInit(globalThis.GameState, 'mode', (mode: GameMode)=>{
            let skeletons = [
                {text: ModeToPlayButton[mode], pointerdown: togglePlay},
                {text: ModeToRecordButton[mode], pointerdown: toggleRecord},
            ].filter(text=>text!=null)
            this.removeChildren();
            skeletons.forEach((buttonSkeleton, i, buttonList)=>{
                let b = new Button({
                    text:buttonSkeleton.text!,  
                    color: "lightgrey",
                    alpha:1,
                    // margin:width*0.1,
                    x:i*width/buttonList.length, y:0, // 
                    height: height,
                    width: width/buttonList.length,
                    // width: width*0.5, height: height*0.8
                })
                b.on("pointerdown",()=>{
                    buttonSkeleton.pointerdown()
                })
                // return b
            // })
            // console.log(this.buttons)
            
            this.addChild(b)
            // this.buttons.forEach(c=>this.addChild(c))
        })
    })
        //  onControlButtonsChange(subscriptionFunc)
         
        // subscriptionFunc()
        // this.playPauseButton = new Button({
        //     text:"⏯️",  
        //     color: "transparent",
        //     alpha:1,
        //     // margin:width*0.1,
        //     x:0, y:0,
        //     height: height,
        //     width: width/2,
        //     // width: width*0.5, height: height*0.8
        // })
        // this.recordButton = new Button({
        //     text:"🔴",  
        //     color: "transparent",
        //     alpha:1,
        //     // margin:width*0.1,
        //     x:width/2, y:0,
        //     height: height,
        //     width: width/2,
        //     // width: width*0.5, height: height*0.8
        // })
        
        // this.playPauseButton.on("pointerdown",()=>{
        //     this.scene.togglePlay()
        //     // TODO: no funciona
            
        // })
        // this.recordButton.on("pointerdown",()=>{
        //     this.scene.toggleRecord()
        // })
        // this.playPauseButton.on(
        // this.addChild(this.playPauseButton)
        // this.addChild(this.recordButton)
        // this.playPauseButton.interactive = true
        
        
        
        
        const background = new Graphics()
        background.rect(0,0,width, height)
        background.fill({color: "white", alpha:1})
        // jsonLevel.timeline.forEach((e)=>{})
        this.addChild(background)
        // breakpoints
    }
}