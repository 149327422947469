import { Application, Container, Renderer, Ticker } from "pixi.js";
import { Settings } from "./Settings";
// import { Scene } from "./scenes/Scene";
type SceneObject = {id:string, sceneFactory: (args?:any)=>IScene, createdObj?: IScene}

export class Manager {
    private constructor() { /*this class is purely static. No constructor to see here*/ }

    // Safely store variables for our game
    private static app: Application;
    private static currentScenes: IScene[];
    private static scenes: SceneObject[]
    private static indexScene: number
    // private static scenesOrder: string[] 
    // Width and Height are read-only after creation (for now)
    private static _width: number;
    private static _height: number;

    // With getters but not setters, these variables become read-only
    public static get width(): number {
        return Manager._width;
    }
    public static get height(): number {
        return Manager._height;
    }

    // Use this function ONCE to start the entire machinery
    public static async init(scenes: SceneObject[], width: number, height: number) {
        this.scenes = scenes
        this.indexScene = 0
        // store our width and height
        Manager._width = width;
        Manager._height = height;

        // Create our pixi app
        Manager.app = new Application<Renderer>();
        let canvas = document.getElementById("pixi-canvas") as HTMLCanvasElement
        canvas.oncontextmenu = (e)=>e.preventDefault()
        await Manager.app.init({
            canvas,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            backgroundColor: Settings.backgroundColor,
            backgroundAlpha: Settings.backgroundColor!='transparent'?1:0, 
            resizeTo: window,
            width: Manager._width,
            height: Manager._height,
            hello: true
        });
        
        // Add the ticker
        Manager.app.ticker.add(Manager.update)
    }

    // Call this function when you want to go to a new scene
    public static changeScene(newScene: IScene): void {
        // Remove and destroy old scene... if we had one..
        // if (!Manager._firstClick){
        //     newScene.eventMode = 'dynamic'
        //     newScene.once("pointerdown", ()=>{
        //         console.log("firstClick")
        //         if (!Manager._firstClick){
        //             Player.init()
        //             Manager._firstClick = true
        //         }
        //     })
        // }
        if (newScene.popup){
            Manager.currentScenes.push(newScene)
        }else{            
            if (Manager.currentScenes) {
                Manager.currentScenes.forEach(scene=>{
                    Manager.app.stage.removeChild(scene);
                    scene.destroy();
                })                
            }
            Manager.currentScenes = [newScene];
        }
        

        // Add the new one
        Manager.app.stage.addChild(newScene);
    }
    public static start(){
        this.indexScene=(this.indexScene)%this.scenes.length
        let selectedScene = this.scenes[this.indexScene]
        let scene = selectedScene.sceneFactory()
        this.scenes[this.indexScene%this.scenes.length].createdObj = scene
        Manager.changeScene(scene)
    }
    public static nextScene(args?:any){
        this.indexScene=(this.indexScene+1)%this.scenes.length
        let selectedScene = this.scenes[this.indexScene]
        // if (selectedScene.createdObj){
        //     Manager.changeScene(selectedScene.createdObj)
        // }else{
            let scene = selectedScene.sceneFactory(args)
            // this.scenes[this.indexScene%this.scenes.length].createdObj = scene
            Manager.changeScene(scene)
        // }
    }
    public static previousScene(args?:any){
        this.indexScene=(this.indexScene-1)%this.scenes.length
        let selectedScene = this.scenes[this.indexScene]
        let scene = selectedScene.sceneFactory(args)
        Manager.changeScene(scene)
    }
    // This update will be called by a pixi ticker and tell the scene that a tick happened
    private static update(ticker: Ticker): void {
        // TickerCallback<any>
        // Let the current scene know that we updated it...
        // Just for funzies, sanity check that it exists first.
        if (Manager.currentScenes) {
            Manager.currentScenes.forEach(scene=>scene.update(ticker))
        }

        // as I said before, I HATE the "frame passed" approach. I would rather use `Manager.app.ticker.deltaMS`
    }
}

// This could have a lot more generic functions that you force all your scenes to have. Update is just an example.
// Also, this could be in its own file...
export interface IScene extends Container {
    popup: boolean
    update(ticker: Ticker): void;
}