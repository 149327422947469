import { ColorSource, Container,Text, ContainerOptions, FederatedPointerEvent, Graphics, Color } from "pixi.js";

import { Grid } from "./Grid";
import { Note } from "@tonaljs/tonal";
import { changeTint } from "../utils/color";
import { getNoteLabel } from "../utils/music";

export enum Orientation {E=0,S,O,N}

export type TileEventCallback = (e: FederatedPointerEvent, tile: TileGraphics, eventType?:string)=>void

// class Line extends Graphics {
//     constructor(args){
//         let {points,stroke,...superArgs} = args
//         super(superArgs)
//         points.forEach((point,i)=>{
//             let {x,y} = point
//             if (i == 0) {
//                 this.moveTo(x,y)
//             }else{
//                     // console.log(startCell, cell, control, midPoint)
//                     // this.lineTo(control.x, control.y)
//                     // this.lineTo(x, y)
//                 this.lineTo(x, y,)
                
    
                
//             }
//         })
//         this.stroke(stroke)
//     }
// }

type TileGraphicsOptions = {
    id: string,
    displayLabel: string,
    height:number,
    note: number,
    grid: Grid
    orientation?:Orientation
    color?: ColorSource
    onActivateTile: TileEventCallback
    onDeactivateTile: TileEventCallback
}

export const Play = ()=>{

}

const drawSegments = (g: Graphics, segments: number[][])=>{
    segments.forEach(([a,b], i)=>{
        if (i===0){
            g.moveTo(a,b)
        }else{
            g.lineTo(a,b)
        }
        
    })
}

export class TileGraphics extends Container {
    public currentKey: string|null
    note: string
    noteMidi: number
    id: string
    bounds?: Graphics
    mainGraphics: Graphics
    // borderGraphics: Graphics
    textGraphics: Text
    grid: Grid
    color: Color
    transform: (g:Graphics)=>void
    drawTile: (g:Graphics)=>Graphics
    onDeactivateTile?:TileEventCallback
    onActivateTile?:TileEventCallback
    constructor(args: (ContainerOptions&TileGraphicsOptions)){
        let {onDeactivateTile, onActivateTile, displayLabel, id, height, orientation, color, note, grid, ...containerProps} = args
        height=height*0.95
        super(containerProps)
        console.log(containerProps, note, id)
        this.onActivateTile = onActivateTile
        this.onDeactivateTile = onDeactivateTile
        this.grid = grid
        this.eventMode = 'dynamic'
        this.noteMidi = note
        this.note = Note.fromMidi(note)
        // set
        this.id = id
        this.currentKey = null
        this.color = new Color(color)
        if ((args?.x!=null && args?.y!=null) || args?.position){
            super.position.x = (args?.x!=null? args.x  : args?.position?.x)!
            super.position.y = (args?.y!=null? args.y : args?.position?.y)!
        }else{
            console.log("Tile not set", args)
        }
        // let size = args.height
        const graphics = new Graphics()
        this.mainGraphics = graphics
        const innerGraphics = new Graphics()
        const buffer = 0.25
        if (args.orientation!=null){
            // let h = size * Math.SQRT2 
                
            let h = height
            let c1 = (t:number)=>[h/2+t*(h-h/2),0+t*(h/2-0)]
            let c2 = (t:number)=>[h+t*(h/2-h),h/2+t*(h-h/2)] 
            let hip = (t:number)=>[h/2,h+t*(0-h)] 
            let getSegments = (buffer:number)=> [
                hip(0.5),
                [hip(0)[0],c1(buffer)[1]],
                c1(buffer),
                c1(1-buffer),
                c2(buffer),
                c2(1-buffer),
                // hip(buffer),
                [hip(0)[0],c2(1-buffer)[1]],
                hip(0.5)
            ] 
            this.drawTile = (g: Graphics)=>{
                // let g = new Graphics()
                drawSegments(g, getSegments(0))
                return g
            }
            // drawSegments(graphics, getSegments(0))
            this.drawTile(graphics)
            drawSegments(innerGraphics, getSegments(buffer))
            this.transform = (g)=>{
                g.rotation = (args.orientation!)*Math.PI/2
                g.pivot.set(h/2,h/2)
            }
            this.transform(graphics)
            this.transform(innerGraphics)
            graphics.zIndex=1
            innerGraphics.zIndex=3
            // graphics.rotation=
            // innerGraphics.rotation=(args.orientation)*Math.PI/2
            // graphics.pivot.set(h/2,h/2)
            // innerGraphics.pivot.set(h/2,h/2)
        }else{
            let size = height / Math.SQRT2 
            let c1 = (t:number)=>[0+0*t,0+size*t]
            let c2 = (t:number)=>[0+t*(size),size+t*(size-size)]
            let c3 = (t:number)=>[size+(size-size)*t,size+(0-size)*t]
            let c4 = (t:number)=>[size+(-size)*t,0+t*(0)]
            this.transform = (g)=>{
                g.pivot.set(size/2,size/2)
                g.rotation=Math.PI/4
            }
            this.transform(innerGraphics)
            
            // let buffer = 0
            drawSegments(innerGraphics, [
                c1(buffer),
                c1(1-buffer),
                c2(buffer),
                c2(1-buffer),
                c3(buffer),
                c3(1-buffer),
                c4(buffer),
                c4(1-buffer),
            ])
            this.drawTile = (g: Graphics)=>{
                // let g = new Graphics()
                // drawSegments(g, getSegments(0))
                g.rect(0, 0, size, size);
                return g
            }
            this.drawTile(graphics)
            this.transform(graphics)
            graphics.zIndex=1
            innerGraphics.zIndex=3
            // graphics.pivot.set(size/2,size/2)
            // graphics.rotation=Math.PI/4
        }
        // this.color save d3 color
        // fill d3 to hex hexToHSL
        const text = new Text({text:getNoteLabel(this.note)})
        text.eventMode = "static"
        text.anchor.x=0.5
        text.anchor.y=0.5
        text.zIndex=2
        // text.rotation -=Math.PI/4
        // text.x = args.height/2
        // text.y = args.height/2
        // this.borderGraphics = graphics.clone()
        // this.borderGraphics =
        graphics.fill(this.color);
        
        innerGraphics.fill({color:'blue', alpha: 0});
        innerGraphics.eventMode = 'dynamic'
        const onPointerEnter = (e: FederatedPointerEvent)=>{
            onActivateTile(e, this, "pointerenter")
                
        }
        const onPointerDown = (e: FederatedPointerEvent)=>{
            onActivateTile(e, this, "pointerdown")
            
        }
        // const onPointerUp = (e: FederatedPointerEvent)=>{
        //     let key2 = PointerManager.freeTouchKey(e)
        //     if (key2){
        //         this.stop(key2)
        //     }
        // }

        innerGraphics.on("pointerdown", (e)=>{
            // console.log("pointerdown", this.id)
            onPointerDown(e)
        })
        innerGraphics.on("pointerenter", (e)=>{
            // console.log("pointerenter", this.id, e.pressure)
            if (e.pressure>0){
                onPointerEnter(e)
            }
        })
        // let ombra = Assets.get("ombra_tile")
        // let ombra_sprite = Sprite.from(ombra)
        // ombra_sprite.position.x+=10
        // ombra_sprite.position.y+=10
        // ombra_sprite.anchor.x = 0.5
        // ombra_sprite.anchor.y = 0.5
        // ombra_sprite.alpha = 0.3
        // ombra_sprite.zIndex = -1
        // ombra_sprite.eventMode = 'dynamic'
        // innerGraphics.on("pointerup", (e)=>{
        //     // console.log("pointerup", this.id)
        //     onPointerUp(e)
        // })
        this.textGraphics = text
        
        this.addChild(graphics)
        
        this.addChild(innerGraphics)
        if (displayLabel){
            this.addChild(text)
        }
        
        // this.interactive = true
        // this.addChild(ombra_sprite)
        // Ticker.shared.add(this.update, this);
    }
    // public onActivate(){
    //     this.mainGraphics.tint =  d3.color(d3.rgb(this.mainGraphics.tint)).brighter(1)
    // }
    // public onDeactivate(){
    //     this.mainGraphics.tint =  d3.color(d3.rgb(this.mainGraphics.tint)).darker(1)
    // }
    public activate(key: string, whosplaying?:string){
        if (key){
            this.currentKey = key
            // this.textGraphics.text += "!!!!"
            // this.mainGraphics.
            this.mainGraphics.tint = changeTint(this.color,{brighter:1})
            if (!this.bounds){
                this.bounds = new Graphics()
                this.bounds.eventMode = 'passive'
                this.drawTile(this.bounds)
                this.transform(this.bounds)
                this.bounds.fill({color: 'black', alpha:0})
                this.bounds.stroke({color:whosplaying==='player'?'black':'grey', width:6, alpha:1})
                this.addChild(this.bounds)
                this.bounds.zIndex=0
                
            }
            // this.mainGraphics.fill("red") ;
            // (changeTint(this.mainGraphics.tint, 1))
            // this.mainGraphics.tint = 
            // console.log(hexToHSL(this.mainGraphics.tint))
            // this.mainGraphics.tint =  
            // change color
        }else{
            // console.log("Error! No key!")
        }        
    }
    public isActive(){
        return this.currentKey!=null
    }
    public deactivate(key?: string){
        if (!key && this.currentKey){
            key = this.currentKey
        }
        if (key){
            // this.mainGraphics.tint =  d3.color(d3.rgb(this.mainGraphics.tint)).darker(1)
            this.currentKey = null
            // changeTint(this.color,{darker:1})
            this.mainGraphics.tint = this.color
            if (this.bounds){
                let index = this.children.findIndex((c)=>c===this.bounds)
                if (index>=0){
                    this.removeChildAt(index)
                    this.bounds?.destroy()
                    this.bounds = undefined
                }
                console.log(index)
            }
            
            
            // console.log("disabled!!")
        }else{
            // console.log("Error! No key!")
        }
    }
    // public update(ticker: Ticker): void {
        // this.mainGraphics.x = 0
        // console.log('update!')
    // }
}