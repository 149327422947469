import * as Tone from 'tone'
export type ScheduledNote = {
    note: string, 
    duration:number,
    t0: number,
    onStart?: (...args)=>void
    onEnd?: (...args)=>void
}
export type ScheduledFunction = {
    start_at_seconds:number,
    end_at_seconds: number,
    onStart: (...args)=>void
    onEnd: (...args)=>void
}
export class Player {
    private constructor() { /*this class is purely static. No constructor to see here*/ }
    private static synth: Tone.PolySynth;
    private static _aliveAttacks: Record<string, string>
    public static playerInit: boolean = false
    private static schedulePromise: Promise<any>|null = null
    private static schedulePromiseControls: {resolve, reject}|null=null
    private static endingCallbacks: (()=>void)[]
    public static now(){
        return Tone.now()
    }
    public static init(){
        // setInterval(() => console.log(Tone.now()), 100);
        // var clock = new Tone.Clock(function(time){
        //     console.log(time);
        // }, 1).start();
        if (!Player.playerInit){
            Player.schedulePromise = null
            // Player.log("HEY")
            console.log("initializing player")
            // store our width and height
            Player._aliveAttacks = {}
            Player.synth = new Tone.PolySynth(Tone.Synth).toDestination()
            Tone.getContext().resume()
            Tone.Transport.start();
            Player.playerInit = true
        }
        
        // we need manual action to trigger this
    }
    
    public static play(key:string, note: string){
        if (!key) key=note
        // if (Player._aliveAttacks[key]!=note){
        //     Player.stop(key)
        // }
        if (!Player._aliveAttacks[key]){
            Player._aliveAttacks[key||note] = note
            // console.log(`Attacking ${Player._aliveAttacks[key]}`)
            Player.synth.triggerAttack(note, Tone.now())
        }
    }
    
    public static stop(key: string){
        // console.log(Player._aliveAttacks)
        if (Player._aliveAttacks[key]){
            // console.log(`Releasing ${Player._aliveAttacks[key]}`)
            Player.synth.triggerRelease([Player._aliveAttacks[key]], Tone.now())
            delete Player._aliveAttacks[key]
        }        
    }
    // public static schedule(callback, t){
    //     const time = Tone.immediate()
    //     return new Promise((resolve, reject)=>{
    //         Tone.Transport.scheduleOnce(callback,time+t)
    //     })
        
    // }
    // public static scheduleToneTimeline(tl, start_at, end_at){
    //     tl.forEachBetween(start_at!, end_at!, (e)=>{
            
    //     })
    // }
    public static scheduleTimeline(notes: ScheduledFunction[], t0=0, _settings: {bpm:number, ticks_per_beat:number}){
        // Tone.Transport.scheduleOnce(()=>{
        //     console.log("TIME 3333")
        // },Tone.now()+3)
        Tone.Transport.seconds = 0
        const time = Tone.Transport.immediate()+t0
        console.log(Tone.now(),Tone.Transport.immediate(), time)
        Player.schedulePromise = new Promise<any>((resolve, reject)=>{
            let newResolve = (args: any)=>{
                Player.schedulePromise = null
                Player.schedulePromiseControls = null
                resolve(args)
            }
            let newReject = (reason?: any)=>{
                Player.schedulePromise = null
                Player.schedulePromiseControls = null
                reject(reason)
            }
            Player.schedulePromiseControls = {resolve: newResolve, reject: newReject}
        })
        let endAtMax = Math.max(...notes.map(({end_at_seconds})=>end_at_seconds))
        let startAtMin = Math.min(...notes.map(({start_at_seconds})=>start_at_seconds))
        
        Tone.Transport.scheduleOnce(()=>{
            Player.schedulePromiseControls?.resolve()
        },endAtMax-startAtMin)
        // setTimeout(()=>console.log(Tone.Transport.now(),Tone.Transport.immediate()),500)
        // let toExecute = {}
        this.endingCallbacks = []
        notes.forEach(({ start_at_seconds, end_at_seconds, onStart, onEnd }) => {
            // Player.synth.triggerAttackRelease(note, duration, time+t0)
            this.endingCallbacks.push(onEnd)
            if (onStart){
                // console.log(,time+start)
                Tone.Transport.scheduleOnce(()=>{
                    console.log(Tone.Transport.immediate())
                    onStart()
                    // console.log(Tone.Transport.now(),Tone.Transport.immediate(), start_at_seconds)
                }, start_at_seconds-startAtMin)
                console.log(start_at_seconds-startAtMin, 'start')
            }
            if (onEnd){
                Tone.Transport.scheduleOnce(()=>{
                    onEnd()
                }, end_at_seconds-startAtMin)
                console.log(time+start_at_seconds-startAtMin, 'end')
            }
        })
        return Player.schedulePromise
    }
    public static cancelSchedule(){
        Tone.Transport.cancel(Tone.immediate())
        // cancelar tots els callbacks de sortida que no s'executaran
        this.endingCallbacks?.forEach(end=>end())
        Player.schedulePromiseControls?.reject("user cancelled")
        // Player.
    }
}