import { Assets } from "pixi.js";
import { midiToTonnetz } from "./grid";
import { songFromMidi } from "./signal/midi/midiConversion";
export const loadJson = (key:string)=>{
    return JSON.parse(JSON.stringify(Assets.get(key)))
}
export const midiTrackToLevel = (events) => {
    // let bpm = periodToBpm(timeDivision)
    let toClose = {}
    let t = 0
    let closed: any[] = []
    // toClose: {}, closed: [], t:0
    events.filter(({ subtype }) => ['noteOn', 'noteOff', 'note'].includes(subtype)).forEach((item) => {
        if (item.noteNumber) {
            if (item.tick!=null){
                t = item.tick
            }else{
                t += item.deltaTime
            }
            let midi = item.noteNumber
            if (item.subtype === 'note'){
                closed.push({start_at: t, end_at: t+item.duration, id: midi, note: midi})
            } else if (item.subtype == 'noteOn') {
                toClose[midi] = { id: midi, note: midi,  start_at: t }
            } else {
                let toCloseObj = toClose[midi]
                if (toCloseObj!=null){
                    closed.push({ ...toCloseObj, end_at: t })
                    delete toClose[midi]
                }
            }
        }
    })
    
    closed = closed.filter(e=>e.end_at-e.start_at>0)
    // debugger
    let grid = midiToTonnetz(closed)
    
    return {timeline: closed, grid}
}

const getTrackNameDict = (song)=>{
    let dictTracks = Object.fromEntries(song.tracks.map(track=>{
        return [track.name, track]
    }))
    // delete dict[undefined]
    return dictTracks
}
export const loadMidi = (key: string )=>{
    return Assets.get(key)
}
export const getMidi = (midiFile: any)=>{
    // let midiFile = globalThis.assets[key]
    // let midiParsed = MidiParser.parse(midiFile)
    let song = songFromMidi(midiFile)
    let events = song.allEvents
    let dictTracks = getTrackNameDict(song)
    let tracksConfig = [...song.tracks];
    let trackMelody;
    let trackBreaks;
    if (song.tracks.length>0){
        trackMelody = dictTracks['melody']
        trackBreaks = dictTracks['breaks']
    }
    if (trackMelody){
        events = trackMelody.events
    }
    // console.log(song, events)
    let result = midiTrackToLevel(events) as {settings:any, timeline:any, grid:any, breaks:any}
    // debugger
    // debugger
    let ticks_per_beat = song.timebase
    let bpm = tracksConfig.reduce((acc, track)=>{
        let tempo = track.getTempo(ticks_per_beat)
        if (tempo!=null && acc == null){
            return tempo
        }
        return acc
    }, null as any)||70
    let settings = {bpm, ticks_per_beat}
    result.settings = settings
    if (trackBreaks){
        // extract breaks
        let tlBreaks = midiTrackToLevel(trackBreaks.events).timeline
        result.breaks = tlBreaks
    }else{
        let timeSign = tracksConfig.reduce((acc, track)=>{
            let timeSign = track.getTimeSignatureEvent(ticks_per_beat)
            if (timeSign!=null && acc == null){
                return timeSign
            }
            return acc
        }, null as any)
        if (timeSign){
            let {numerator, denominator} = timeSign
            let unit = 4 / denominator
            let lengthUnit = unit*ticks_per_beat
            let lengthBar = numerator*lengthUnit
            let i = 0
            let breaks: {start_at:number, end_at:number}[] = []
            let pending: typeof breaks = []
            while(i<result.timeline[result.timeline.length-1].end_at){
                let notesInside = result.timeline.filter(n=>(n.start_at>=i)&&(n.end_at<=i+lengthBar))
                if (notesInside.length==0){
                    if (i>13000){
                        console.log("a")
                    }
                    pending.push({start_at:i, end_at:i+lengthBar})
                }else{
                    breaks = breaks.concat(pending)
                    pending=[]
                    breaks.push({start_at:i, end_at:i+lengthBar})
                }
                i += lengthBar
            }
            result.breaks = breaks
        }
    }
    
    return result
}