import { Container, Graphics, Assets, Ticker } from "pixi.js";
import { manifest } from "../assets";
import { IScene, Manager } from "../Manager";
// import { GameScene } from "./GameScene";

export class LoaderScene extends Container implements IScene {

    // for making our loader graphics...
    // private loaderBar: Container;
    // private loaderBarBoder: Graphics;
    // private loaderBarFill: Graphics;
    public popup = false
    private timeoutPassed: boolean = false
    private ready: boolean = false
    constructor(_logo, ms) {
        super();
        // let splashy = Sprite.from(logo)
        // splashy.x = Manager.width/2
        // splashy.y = Manager.height/2
        // splashy.anchor.x = 0.5
        // splashy.anchor.y = 0.5
        // this.addChild(splashy);
        let invisibleButton = new Graphics();
        // right order! rect -> fill
        invisibleButton.rect(0, 0, Manager.width, Manager.height);
        invisibleButton.fill({color:"blue", alpha:0});
        invisibleButton.eventMode = "dynamic"
        this.addChild(invisibleButton)
        // const loaderBarWidth = Manager.width * 0.8;
        
        // this.loaderBarFill = new Graphics();
        // this.loaderBarFill.fill({color:0x008800, alpha:1})
        // this.loaderBarFill.rect(0, 0, loaderBarWidth, 50);
        // this.loaderBarFill.scale.x = 0;

        // this.loaderBarBoder = new Graphics();
        // // this.loaderBarBoder.lineStyle(10, 0x0, 1);
        // this.loaderBarBoder.stroke({width:10, color:0x0, alpha:1})
        // this.loaderBarBoder.rect(0, 0, loaderBarWidth, 50);

        // this.loaderBar = new Container();
        // this.loaderBar.addChild(this.loaderBarFill);
        // this.loaderBar.addChild(this.loaderBarBoder);
        // this.loaderBar.position.x = (Manager.width - this.loaderBar.width) / 2;
        // this.loaderBar.position.y = (Manager.height - this.loaderBar.height) / 2;
        
        setTimeout(()=>{
            this.timeoutPassed = true
            this.gameLoaded()
        }, ms||0)
        this.initializeLoader().then(() => {
            this.ready = true
            this.gameLoaded()
        })
    }

    private async initializeLoader(): Promise<void>
    {
        await Assets.init({ manifest: manifest });
        const bundleIds =  manifest.bundles.map(bundle => bundle.name);
        await Assets.loadBundle(bundleIds, this.downloadProgress.bind(this));
    }

    private downloadProgress(_progressRatio: number): void {
        // this.loaderBarFill.scale.x = progressRatio;
    }

    private gameLoaded(): void {
        // Change scene to the game scene!
        console.log("GAME LOADED!")
        if (this.timeoutPassed && this.ready){
            Manager.nextScene();
        }
        
    }

    public update(_ticker: Ticker): void {
        // To be a scene we must have the update method even if we don't use it.
    }
}