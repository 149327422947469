export const getSecondsTimeline = (tl: any[], settings: {ticks_per_beat:number, tempo?:number, bpm?:number})=>{
      // Calculate the duration of one beat in seconds
  const secondsPerBeat = settings.bpm?(60/settings.bpm):(settings.tempo! / 1000000); // Convert microseconds to seconds

  // Calculate the duration of one tick in seconds
  const secondsPerTick = secondsPerBeat / settings.ticks_per_beat;

  // Convert the given number of ticks to seconds
//   const durationInSeconds =  secondsPerTick;
    let lastNoteEnd = 0
    let newTl: any[] = []
    for (let i = 0; i<tl.length;i++){
        let e = tl[i]
        if (e.start_at-lastNoteEnd > settings.ticks_per_beat){

            let silence = {
                start_at: lastNoteEnd,
                end_at: e.start_at
            }
            newTl.push(silence)
        }
        lastNoteEnd = e.end_at
        let note = {
            ...e,
            start_at_seconds:e.start_at*secondsPerTick,
            end_at_seconds:e.end_at*secondsPerTick,
            duration_seconds:e.duration*secondsPerTick,
        }
        newTl.push(note)
    }
    return newTl
}
const Notes = {
    "C": "Do",
    "D": "Re",
    "E": "Mi",
    "F": "Fa",
    "G": "Sol",
    "A": "La",
    "B": "Si"
};
export const getNoteLabel = (note)=>{
    let base = Notes[note[0]]
    // console.log(note)
    if (note.includes("#")){
        base += "#"
    }
    if (note.includes("b")){
        base += "-b"
    }
    return base
}