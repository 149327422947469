import { Midi } from "@tonaljs/tonal";

export function drawList(target, listEvents){
    listEvents.forEach(e=>{
      target[e.type](...e.args)
    })
  }
export const DIRECTIONS = {
    "SE": { "x": 0.5, "y": 0.5 },
    "S": { "x": 0, "y": 1 },
    "SO": { "x": -0.5, "y": .5 },
    "O": { "x": -1, "y": 0 },
    "NO": { "x": -0.5, "y": -0.5 },
    "N": { "x": 0, "y": -1 },
    "NE": { "x": 0.5, "y": -0.5 },
    "E": { "x": 1, "y": 0 }
};
const getId = ({x,y})=>`${x},${y}`
export const generateGrid = (w, h, size = 1) => {
    // let halfW = w-Math.floor(w)
    // let halfH = h-Math.floor(h)
    // w += halfW
    // h += halfH
    let returnVal: any = []
    // ho puc girar i=0 i<w
    for (let i = w; i > 0; i -= 0.5) {
        let rowType = (i - Math.floor(i))
        for (let j = rowType; j < h; j += 1) {
            let value: any = null

            // if (i==w-0.5 && j>=h-1){
            //   continue
            // }
            let orientation: any = null
            // i===0
            if (i === w) {
                orientation = "O"
                // i+0.5==
            } else if (i - 0.5 <= 0) {
                orientation = "E"
            }
            if (j == 0) {
                if (orientation) continue
                orientation = 'S'
            } else if (j + 1 > h) {
                if (orientation) continue
                orientation = 'N'
            }
            let x = size * (i - 1) + size / 2
            // x = parseFloat(x.toFixed(1))
            let y = (size * (j))
            // y = parseFloat(y.toFixed(1))
            value = { x, y, orientation, id: getId({x:i, y:j}) }
            if (value) {
                returnVal.push(value)
            }

        }
    }
    return returnVal
}
export const NOTE_DELTA = {
    0: [],
    1: ["E"], // C#e
    2: ["NO", "O"], // D
    3: ["NO"], // D# Eb
    4: ["NE"], // E
    5: ["NO", "O", "NO"], // F
    6: ["NO", "NO"], // F# Gb
    7: ["N"], // G
};
export const EXTRA_COLUMN = {
    1: ["NO", "O", "O"],
    5: ["NE", "E"]
}
export const ORIENTATIONS_EXTRA_COLUMN = {
    1: "E",
    5: "O"
}
const multiply = (dir, num) => ({ ...dir, x: dir.x * num, y: dir.y * num })
const add = (dir, dir2) => ({ ...dir, x: dir.x + dir2.x, y: dir.y + dir2.y })
export const arrayDirectionsToVector = (arrayDir) => {
    let current = { x: 0, y: 0 };
    arrayDir.forEach((k) => {
        if (typeof k === "string") {
            current = add(current, DIRECTIONS[k]);
        } else {
            let abs = Math.abs(k);
            let orientation = k / abs;
            let dir = arrayDirectionsToVector(NOTE_DELTA[abs]);
            current = add(current, multiply(dir, orientation));
        }
    });
    return current;
};
export const directionsNoteDelta = Object.fromEntries(
    Object.entries(NOTE_DELTA).map(([k, arrayDir]) => {
        return [k, arrayDirectionsToVector(arrayDir)];
    })
);

export const directionsExtraNotes = Object.fromEntries(
    Object.entries(EXTRA_COLUMN).map(([k, arrayDir]) => {
        return [k, arrayDirectionsToVector(arrayDir)];
    })
);
const cicle = arrayDirectionsToVector(["O", "O", "O", "NO"])
const getCells = (note, numBase, base = { x: 0, y: 0 }, offset = 0) => {
    let delta = note - numBase
    let numN = Math.floor(delta / 7)
    let dirModule = directionsNoteDelta[delta % 7]
    if (offset) {
        dirModule = add(dirModule, multiply(cicle, offset))
    }

    let norths = multiply(DIRECTIONS.N, numN)
    let finalDir = add(norths, dirModule)
    let cell = add(base, finalDir)
    let returnVal = [cell]
    // if (EXTRA_COLUMN[delta % 7]) {
    //     let dirModule2 = directionsExtraNotes[delta % 7]
    //     let finalDir2 = add(norths, dirModule2)
    //     returnVal.push({ ...add(base, finalDir2), orientation: ORIENTATIONS_EXTRA_COLUMN[delta % 7] })
    // }
    return returnVal
}
// def translate_top_right(cells):
//     min_x = min([c.x for c in cells])
//     min_y = min([c.y for c in cells])
//     max_x = max([c.x for c in cells])
//     max_y = max([c.y for c in cells])
//     new_array = []
//     for c in cells:
//         c.x += abs(min_x)
//         c.y += abs(min_y)
//         new_array.append(c)
//     return new_array
export function translateTopRight(cells){
    let xs = cells.map(({x})=>x)
    let ys = cells.map(({y})=>y)
    let minX = Math.abs(Math.min(...xs))
    let minY = Math.abs(Math.min(...ys))
    return cells.map(({x,y,...obj})=>({...obj, x: x+minX, y: y+minY }))
}    
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
export function midiToTonnetz(listNotesObj) {
    let listNotes = listNotesObj.map(({note})=>note).filter(onlyUnique);
    console.log(listNotes)
    let minNote = Math.min(...listNotes)
    let root = { x: 0, y: 0 }
    let result = listNotes.reduce((acc, note) => {
        let cells = getCells(note, minNote, root).map(r => ({ ...r, note, label: Midi.midiToNoteName(note, { pitchClass: true, sharps: true }), id:note }))
        return [...acc, ...cells]
    }, [] as any[])
    // let xs = result.filter(p => !p.orientation).map((p: any) => p.x)
    // let ys = result.filter(p => !p.orientation).map((p: any) => p.y)
    // let [maxX, minX] = [Math.max(...xs), Math.min(...xs)]
    // let [maxY, minY] = [Math.max(...ys), Math.min(...ys)]
    // maxY = maxY - minY
    // maxX = maxX - minX
    // let tiles = result//.map((r: any) => ({ ...r, x: r.x - minX, y: maxY - (r.y - minY) })).map(r => ({...r, id: getId(r)} ))
    // debugger
    return translateTopRight(result)
}
export const ruleTonnetz = (tiles, initialTile: { position: { x: number, y: number }, name: string, pitchClass: number }) => {
    let { x: xBase, y: yBase } = initialTile.position
    let id = `${xBase}, ${yBase}`
    let valueBase = Midi.toMidi(`${initialTile.name}${initialTile.pitchClass}`)
    // let done = (new Array(tiles.length)).fill(1).map(((k,i)=>i))
    let result = tiles.map((t) => {
        let note
        if (t.id === id) {
            note = valueBase
        } else {
            let [x, y] = t.id.split(",").map(p => parseFloat(p))
            note = valueBase
            let deltaY = Math.floor(y - yBase)
            note -= deltaY * 7
            if (y - yBase !== deltaY) {
                note -= 3
            }
            let deltaX = Math.floor(x - xBase)
            note += deltaX
        }
        return { ...t, midi: note }
    }, {})
    // tiles.forEach()
    return result
}
export const getSplitPattern = (object, path, funcDot = (obj, slicePath,) => obj ? obj[slicePath] : null, char = ".") => {
    return path.split(char).reduce((acc, slicePath,) => {
        return funcDot(acc, slicePath,)
    }, object)
}
export const setSplitPattern = (object, path, value, funcDot = (objVal, slicePath) => objVal ? objVal[slicePath] : null, char = ".", createObj = (objVal, slicePath) => objVal[slicePath] = {}) => {
    let splitted = path.split(char)
    let [values, lastValue] = [splitted.slice(0, splitted.length - 1), splitted[splitted.length - 1]]
    let latestObj = values.length >= 1 ? values.reduce((acc, slicePath,) => {
        let v = funcDot(acc, slicePath)
        if (!v) {
            createObj(acc, slicePath)
            v = funcDot(acc, slicePath)
        }
        return v
    }, object) : object
    if (lastValue) {
        latestObj[lastValue] = value
    }
    return object
}
export const getDefaultObject = (schema) => {
    if (!schema) return null
    // if (schema?.properties?.included?.properties?.discontinued) debugger
    if (schema.default != null) return schema.default
    if (schema.properties) {
        let properties = Object.entries(schema.properties)
        if (schema.properties['_selected'] && schema.properties['_selected'].default) {
            properties = properties.filter(([k,]) =>/*k!='_selected'&& */schema.properties['_selected'].default.includes(k))
        }
        let listProps = properties.map(([k, v]) => [k, getDefaultObject(v)]).filter(([, v]) => v != null)
        if (listProps.length > 0) {
            return Object.fromEntries(listProps)
        }
    }
}
export const bpmToPeriod = (bpm) => {
    return (60 / bpm) * 1000
}
export const periodToBpm = (period) => {
    return 1000 * 60 / period
    // return (60/bpm)*1000
}
        
export const midiTrackToTimeline = (track, timeDivision) => {
    let bpm = periodToBpm(timeDivision)
    let { closed } = track?.event?.filter(({ type }) => [8, 9].includes(type)).map(d => ({ ...d, deltaTime: d.deltaTime / timeDivision })).reduce(({ toClose, closed, t }, item) => {
        if (item.data && Array.isArray(item.data)) {
            t += item.deltaTime
            let midi = item.data[0]
            if (item.type == 9) {
                toClose[midi] = { midi, eventType: 'sprite', t0: t }
            } else {
                let toCloseObj = toClose[midi]
                
                if (toCloseObj!=null){
                    closed.push({ ...toCloseObj, tf: t })
                    delete toClose[midi]
                }
            }
            return { toClose, closed, t }
        }
        return { toClose, closed, t }
    }, { toClose: {}, closed: [], t:0 })
    closed = closed.filter(e=>e.tf-e.t0>0) 
    let durations = closed.map(e=>e.tf-e.t0)
    durations.sort((a,b)=>a-b)
    let mcm_list:any[] = []
    durations.forEach((a:any)=>{
        if (mcm_list.length==0){
            mcm_list.push(a)
        }else{
            let push = true
            mcm_list.forEach((m)=>{
                if (a%m==0){
                    push = false
                }
            })
            if (push){
                mcm_list.push(a)
            }
        }
    })
    
    let multiplier_inverse = mcm_list.reduce((acc, e)=>{
        return acc*(1/e)
    },1)
    multiplier_inverse = Math.round(multiplier_inverse)
    let newClosed = closed.map(e=>({...e, t0: Math.round(e.t0*multiplier_inverse), tf: Math.round(e.tf*multiplier_inverse)}))
    let newBpm = bpm*multiplier_inverse
    return {tl: newClosed, bpm: newBpm}
}
export function unique(value, index, array) {
    return array.indexOf(value) === index;
}
