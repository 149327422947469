import { ColorSource, Container, Graphics, GraphicsOptions, Text } from "pixi.js"

export type ButtonOptions = {
    // margin?: number,
    isButton?: boolean,
    parentH?: number
    parentW?: number
    color?: ColorSource,
    text?: string,
    x:number, y: number
}
export class Button extends Container {
    textContainer: Text
    button: Graphics
    constructor(args: GraphicsOptions&ButtonOptions){
        let {isButton, zIndex, width, height, color, text, ...superArgs} = args
        // let {x,y} = superArgs
        super(superArgs)
        // margin = margin ?? 0
        width = width ?? 30
        height = height ?? 30
        // let [realW, realH] = [width-margin*2, height-margin*2]
        // let [realX, realY] = [x,y]//[x-width/2, y-height/2]
        this.button = new Graphics()
        this.button.rect(0,0,width, height)
        this.button.fill({color:color??'transparent', alpha: args.alpha??1})
        this.textContainer = new Text({text})
        this.textContainer.anchor.x = 0.5
        this.textContainer.anchor.y = 0.5
        this.textContainer.x = width/2
        this.textContainer.y = height/2
        this.textContainer.eventMode = 'dynamic'
        this.addChild(this.button)
        this.addChild(this.textContainer)
        this.eventMode = 'dynamic'
        this.zIndex = zIndex??1
        if (isButton == null || isButton){
            this.cursor = 'pointer'
            this.textContainer.cursor = 'pointer'    
        }
        
    }
    changeText(newText: string){
        this.textContainer.text = newText
    }
}