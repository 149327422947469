import { FillStyleInputs, Graphics, GraphicsOptions } from "pixi.js";
import { boundingBox, unitVector } from "../utils/geometry";

type ArcOptions = {
    points: {x:number,y:number}[]
    closed: boolean,
    stroke: FillStyleInputs
}

export class Arc extends Graphics {
    constructor(args: GraphicsOptions&ArcOptions){
        let {points, closed, stroke, ...superArgs} = args
        super(superArgs)
        this.fill({color:'black', width:1})
        // this.beginPath()
        // let current = args.points[0]
        let acc = {}
        let {width:domainWidth, height: domainHeight} = boundingBox(points)
        let parabolaWeight = Math.min(domainWidth, domainHeight)/10
        // let parabolaWeight = Math.min(domainWidth, domainHeight)/10
        // console.log(a)
        points.forEach((point, i)=>{
            let {x,y} = point
            if (i == 0) {
                this.moveTo(x,y)
            }else{
                // console.log()
                let {x:startX, y:startY} = points[i-1]
                if (startX!=x || startY!= y) {
                    let cell = `${x}-${y}`
                    let startCell = `${startX}-${startY}`
                    if (!acc[startCell]) acc[startCell] = {}
                    if (!acc[startCell][cell]) acc[startCell][cell] = 0
                    acc[startCell][cell] += 1
                    let addition = parabolaWeight*acc[startCell][cell]
                    if (startX > x || startY > y){
                        // addition *=   -1
                    }
                    let midPoint = {x:(startX+x)/2, y: (startY+y)/2}
                    let normal = unitVector({x:y-startY, y: startX-x})
                    let control = {x: midPoint.x+normal.x*addition,y:midPoint.y+normal.y*addition}
                    // this.bezierCurveTo(control.x, control.y, control.x, control.y, x,y,0)
                    // console.log(startCell, cell, control, midPoint)
                    // this.lineTo(control.x, control.y)
                    // this.lineTo(x, y)
                    this.quadraticCurveTo(control.x, control.y, x,y,0)
                }

                
            }
            
        })
        // points.forEach(({x,y}, i)=>{
        //     if (i==0) this.moveTo(x,y)
        //     else {
        //         let start = points[i]
        //         this.arcTo(x,y, start.x, start.y, -2)
        //     }
        // })
        this.stroke(stroke)
        // this.closePath()
    }
}
