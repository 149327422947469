import * as d3 from 'd3-scale'
export type BasicPoint = {x:number, y:number}
export type RectSize = {
    width: number
    height:number
}
export type BasicRect = BasicPoint&RectSize
export const boundingBox = (points: BasicPoint[])=>{
    let xs = points.map(({x})=>x)
    let ys = points.map(({y})=>y)
    let minX = Math.min(...xs)
    let maxX = Math.max(...xs)
    let minY = Math.min(...ys)
    let maxY = Math.max(...ys)
    return {minX, maxX, minY, maxY, width:maxX-minX, height: maxY-minY}
}
export const centerPoints = (points: BasicPoint[],rect: RectSize)=>{
    let xs = points.map(({x})=>x)
    let ys = points.map(({y})=>y)
    let minX = Math.min(...xs)
    let maxX = Math.max(...xs)
    let minY = Math.min(...ys)
    let maxY = Math.max(...ys)
    let diffX = maxX-minX
    let diffY = maxY-minY
    let [offsetX, offsetY] = [0,0]
    if (rect.width && diffX<rect.width){
        offsetX += (rect.width - diffX)/2
    }
    if (rect.height && diffY<rect.height){
        offsetY += (rect.height - diffY)/2
    }
    points.forEach(point=>{
        point.x = point.x+offsetX
        point.y = point.y+offsetY
    })
    return points
}
export const scalePoints = (points: BasicPoint[], {x,y,width,height}:BasicRect, center:boolean=false, _copy:boolean=false)=>{
    let xs = points.map(({x})=>x)
    let ys = points.map(({y})=>y)
    let minX = Math.min(...xs)
    let maxX = Math.max(...xs)
    let minY = Math.min(...ys)
    let maxY = Math.max(...ys)
    let imageLengthX, imageLengthY;
    if (width<height){ // (maxX-minX)/width>(maxY-minY)/height
        imageLengthX = width
        imageLengthY = width
    }else{
        imageLengthX = height
        imageLengthY = height
    }
    let scX = d3.scaleLinear([minX, maxX],[x, x+imageLengthX])
    let scY = d3.scaleLinear([minY, maxY],[y, y+imageLengthY])
    points.forEach((point)=>{
        // ({x:scX(x), y:scY(y),...args})
        point.x = scX(point.x)
        point.y = scY(point.y)
    })
    if (center){
        points = centerPoints(points, {width, height})
    }
    return points
    
}
export const unitVector = (v:BasicPoint)=>{
    let mod = Math.sqrt(v.x*v.x+v.y*v.y) 
    return {x:v.x/mod, y:v.y/mod}
}