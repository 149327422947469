import { type AssetsManifest } from "pixi.js";



export const manifest:AssetsManifest = {
    bundles: [
        // {
        //     name: "splash",
        //     assets: {
        //         "logo": "./sprites/logo.png"
        //     }
        // },
        {
            name : "bundleName",
            assets: {
                "steps": "./levels/steps.mid",
                "mario": "./levels/mario.mid",
                "girona": "./levels/girona.mid",
                "frere": "./levels/frere-jacques-level.mid",
                "two_octaves": "./levels/two_octaves.json",
                "somewhere": "./levels/somewhere-simple.mid",
                "estato": "./levels/estato-sta-finendo-long.mid",
                "fcb": "./levels/estato-sta-finendo-short.mid",
                "simple": "./levels/simple.json"
                // "ombra_tile": "./ombra.png"
                // "another image" : "./monster.png",
            }
        }
    ]
}